import { Children, cloneElement, ReactElement } from 'react';

import { TabsPanelProps } from './interface-tabs';

export const TabPanels = <T,>({ value, children }: TabsPanelProps<T>) => {
  const childrenWithProps = Children.map(children, (child, index) =>
    cloneElement(child as ReactElement, { index, currentValue: value }),
  );

  return <>{childrenWithProps}</>;
};
