import useTranslation from 'next-translate/useTranslation';
import {
  Children,
  cloneElement,
  FC,
  MouseEventHandler,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Translate } from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import {
  EActionAttribute,
  EIcon,
  EPosition,
  ESize,
  IconPosition,
  StyleRuleDisplay,
} from '@core/type';

import { ButtonIcon } from '../button/ButtonIcon';
import { Box } from '../layout/Box';
import { TabChildProps, TabsProps } from './interface-tabs';
import { setOnChangeTabEvent } from './tab-event';

const getTabsResponsiveHandler = (tabValues: (string | number)[], value: string | number) => {
  return (maxTabsLength: number) => {
    return (prev: StyleRuleDisplay[]) => {
      const tabsLength = prev.length;
      const currentIndex = tabValues.indexOf(value);

      if (tabsLength) {
        const tabsRemainder = tabsLength - currentIndex;

        return prev.map((_, index) => {
          const isRightSideTabsVisible =
            tabsRemainder >= maxTabsLength &&
            index >= currentIndex &&
            index < currentIndex + maxTabsLength;
          const isLeftSideTabsVisible =
            tabsRemainder < maxTabsLength && tabsLength - index <= maxTabsLength;

          return isRightSideTabsVisible || isLeftSideTabsVisible ? 'block' : 'none';
        });
      } else {
        let visibleTabsCount = 0;

        return tabValues.map((_, index) => {
          const tabsRemainder = Math.abs(currentIndex - index);
          visibleTabsCount += 1;

          if (
            tabsRemainder >= maxTabsLength ||
            (index > currentIndex && visibleTabsCount > maxTabsLength)
          ) {
            return 'none';
          }

          return 'block';
        });
      }
    };
  };
};

export const Tabs = <T extends number | string>({
  children: childrenProp,
  onChange,
  value,
  xs = 1,
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  sm = 2,
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  md = 3,
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  lg = 3,
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  xl = 4,
  ...restProps
}: TabsProps<T>) => {
  const { mediaQuery } = useContextMediaQuery();
  const { t: tAria } = useTranslation(Translate.common.ARIA);
  const [tabsVisibility, setVisibleTabs] = useState<StyleRuleDisplay[]>([]);

  const responsive = { xs, sm, md, lg, xl };

  const [maxTabsLength, setMaxTabsLength] = useState<number>(responsive[mediaQuery]);

  const children = (Children.toArray(childrenProp) as TabChildProps<T>[]).filter(
    ({ props: { isTabVisible } }) => isTabVisible ?? true,
  );

  const tabValues = children.map(
    ({ props: { value } }: TabChildProps<T>, index) => value ?? index,
  ) as T[];

  const prevIndex = tabValues.indexOf(value) - 1;
  const nextIndex = tabValues.indexOf(value) + 1;
  const isPrevActive = prevIndex >= 0;
  const isNextActive = nextIndex <= tabValues.length - 1;
  const handleTabsResponsive = getTabsResponsiveHandler(tabValues, value);

  const childrenWithProps = useMemo(
    () =>
      children.map((child, index) =>
        cloneElement(child as ReactElement, {
          onChange,
          value: tabValues[index],
          display: tabsVisibility[index],
          isActive: tabValues[index] === value,
          isFullwidth: maxTabsLength < tabValues.length,
        }),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [maxTabsLength, tabsVisibility, tabValues, value, children],
  );

  const handleTabsVisibility = useCallback(
    (indexPosition: number) => {
      return (display: StyleRuleDisplay, index: number) => {
        if (Math.abs(index - indexPosition) >= maxTabsLength) {
          return 'none';
        } else if (index === indexPosition) {
          return 'block';
        } else {
          return display;
        }
      };
    },
    [maxTabsLength],
  );

  const handlePrevButton: MouseEventHandler<HTMLDivElement> = (e) => {
    if (isPrevActive) {
      onChange(setOnChangeTabEvent<T>(e, tabValues[prevIndex]));
      setVisibleTabs((prev) => prev.map(handleTabsVisibility(prevIndex)));
    }
  };

  const handleNextButton: MouseEventHandler<HTMLDivElement> = (e) => {
    if (isNextActive) {
      onChange(setOnChangeTabEvent<T>(e, tabValues[nextIndex]));
      setVisibleTabs((prev) => prev.map(handleTabsVisibility(nextIndex)));
    }
  };

  useEffect(() => {
    setMaxTabsLength(responsive[mediaQuery]);
    setVisibleTabs(handleTabsResponsive(responsive[mediaQuery]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaQuery]);

  return (
    <Box
      as={EActionAttribute.UL}
      role={'tablist'}
      display={'flex'}
      marginBottom={'-8px'}
      {...restProps}
    >
      <TabIconButton
        onClick={handlePrevButton as MouseEventHandler}
        orientation={EPosition.LEFT}
        isVisible={tabValues.length > maxTabsLength}
        isDisabled={!isPrevActive}
        aria-label={tAria('prevButton')}
      />
      {childrenWithProps}
      <TabIconButton
        onClick={handleNextButton as MouseEventHandler}
        orientation={EPosition.RIGHT}
        isVisible={tabValues.length > maxTabsLength}
        isDisabled={!isNextActive}
        aria-label={tAria('nextButton')}
      />
    </Box>
  );
};

type TabIconButtonProps = {
  orientation: IconPosition;
  isDisabled: boolean;
  isVisible: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  'aria-label': 'prev-button' | 'next-button';
};

const TabIconButton: FC<TabIconButtonProps> = ({
  orientation,
  isVisible,
  isDisabled,
  ...restProps
}) =>
  isVisible && (
    <Box width={'56px'} height={'56px'} flexShrink={0}>
      <ButtonIcon
        iconProps={{
          type: EIcon.ANGLE,
          size: ESize.MD,
          margin: '0 auto',
          orientation,
          display: isDisabled ? 'none' : 'block',
        }}
        role={'switch'}
        aria-checked={false}
        width={'100%'}
        height={'100%'}
        disabled={isDisabled}
        display={isDisabled ? 'none' : 'block'}
        borderRadius={'8px 8px 0 0'}
        {...restProps}
      />
    </Box>
  );
