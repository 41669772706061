import { Paper } from '../paper/Paper';
import { TabPanelProps } from './interface-tabs';

export const TabPanel = <T,>({
  value,
  index,
  currentValue,
  children,
  isTabPanelVisible = true,
  ...restProps
}: TabPanelProps<T>) =>
  (value ?? index) === currentValue &&
  isTabPanelVisible && (
    <Paper role={'tabpanel'} hasElevation position={'relative'} borderRadius={'8px'} {...restProps}>
      {children}
    </Paper>
  );
