import { MouseEvent } from 'react';

import { TabsChangeEvent } from '@core/type';

export const setOnChangeTabEvent = <T>(
  event: MouseEvent<HTMLDivElement>,
  value: T,
): TabsChangeEvent<T> => ({
  ...event,
  target: { ...event.target, value },
});
