import type { FC } from 'react';

import { useContextUtil } from '@core/context';
import { EColor, EIcon } from '@core/type';

import { Button } from './Button';
import { SubmitButtonProps } from './interface-button';

export const ButtonSubmit: FC<SubmitButtonProps> = (props: SubmitButtonProps): JSX.Element => {
  const { recaptchaKey } = useContextUtil();

  return (
    <Button
      role="button"
      type="submit"
      endIcon={EIcon.GA_LOGO}
      colorIcon={EColor.WARNING}
      hiddenIcon={!props.isLoading}
      {...props}
      data-sitekey={recaptchaKey}
      data-callback="onSubmit"
      data-action="submit"
    />
  );
};
