import styled, { CSSObject } from 'styled-components';

import { WithThemeProps } from '@core/type';

import { IFrameStyleProps } from './interface-iframe';

export const IFrame = styled.iframe(
  ({
    height,
    width,
    style,
    boxProps: {
      color,
      boxShadow,
      borderColor,
      fontWeight,
      backgroundColor,
      width: boxWidth,
      height: boxHeight,
      ...restBoxProps
    },
  }: IFrameStyleProps & WithThemeProps) =>
    ({
      width: width ?? boxWidth,
      height: height ?? boxHeight,
      ...restBoxProps,
      ...style,
    }) as CSSObject,
);
