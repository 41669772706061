import { FC } from 'react';
import styled, { CSSObject } from 'styled-components';

import { WithThemeProps } from '@core/type';
import { getLinkColorProps } from '@core/util';

import { Box } from '../layout';
import { StyledLink } from '../link';
import { TextWithLineLimitStyled } from '../typography';
import { StyledCardHeader } from './StyledCardHeader';
import { StyledCardMediaWrapperProps } from './interface-card';

export const StyledCardMediaWrapper: FC<StyledCardMediaWrapperProps> = styled(Box)(({
  theme: {
    palette,
    structure: {
      link: { underlineSecondPath },
    },
  },
  headerColor,
}: Omit<StyledCardMediaWrapperProps, 'children'> & WithThemeProps) => {
  const { hover } = getLinkColorProps({ palette, color: headerColor });

  return {
    borderRadius: 4,
    overflow: 'hidden',
    cursor: 'pointer',

    [`&:hover + ${StyledCardHeader}`]: {
      [`${TextWithLineLimitStyled.toString()} ${StyledLink}`]: {
        ...hover,
        ...underlineSecondPath,
      },
    },
  } as CSSObject;
});
