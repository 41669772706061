import { useEffect, useState } from 'react';

import { formatCurrency } from '@core/util';

import { InputRange } from './InputRange';
import { InputRangePriceProps } from './interface-input';

export const InputRangePrice = ({
  defaultValue,
  language,
  currency,
  onChange,
  ...props
}: InputRangePriceProps) => {
  const [value, setValue] = useState<number>(defaultValue);
  const [tooltipValue, setTooltipValue] = useState<string>(
    formatCurrency(language, currency, defaultValue),
  );

  const handleChange = (value: number) => {
    setTooltipValue(formatCurrency(language, currency, value));
  };

  const handleMouseUp = (value: number) => {
    setValue(Number(value));

    if (onChange) {
      onChange(Number(value));
    }
  };

  useEffect(() => {
    setTooltipValue(formatCurrency(language, currency, value));
  }, [language, currency]);

  return (
    <InputRange
      defaultValue={defaultValue}
      tooltipValue={tooltipValue}
      onChange={handleChange}
      onMouseUp={handleMouseUp}
      {...props}
    />
  );
};
