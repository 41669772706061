import Script from 'next/script';
import { memo } from 'react';

import { useContextUtil } from '@core/context';
import { getStaticPathScripts } from '@core/media';

import { ChartTradingViewProps } from './interface-charts';

export const ChartTradingView = memo(
  ({
    width,
    height,
    symbol,
    allowSymbolChange,
    autosize,
    calendar,
    enablePublishing,
    interval,
    locale,
    style,
    supportHost,
    theme,
    timezone,
  }: ChartTradingViewProps) => {
    const { nonce, cdn } = useContextUtil();

    return (
      <div
        id="tradingview-widget-container"
        className="tradingview-widget-container"
        style={{ height: height ?? '100%' }}
      >
        <Script
          id="tradingview-widget"
          strategy="lazyOnload"
          nonce={nonce}
          src={getStaticPathScripts('embed-widget-advanced-chart.js', cdn)}
          onLoad={() => {
            const iframe = document.getElementById('tradingview-widget');
            const container = iframe.parentNode;

            document.getElementById('tradingview-widget-container').appendChild(container);
          }}
        >
          {JSON.stringify({
            width: `${width ?? '100%'}`,
            height: `${height ?? '100%'}`,
            autosize,
            symbol,
            interval,
            timezone,
            theme,
            style,
            locale,
            enable_publishing: enablePublishing,
            allow_symbol_change: allowSymbolChange,
            calendar,
            support_host: supportHost,
          })}
        </Script>
      </div>
    );
  },
);

ChartTradingView.displayName = 'ChartTradingView';

export const getChartTradingView = (sectionProps: ChartTradingViewProps) => {
  return <ChartTradingView {...sectionProps} />;
};
