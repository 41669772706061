import { keyframes } from 'styled-components';

import { getStaticPathIconFolder } from '@core/media';

import { Image } from '../image';
import { Box } from '../layout';

const spinningAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const Loader = () => {
  return (
    <Box
      animation={spinningAnimation}
      animationDuration="2s"
      animationIterationCount="infinite"
      animationTimingFunction="linear"
      width="25px"
      height="25px"
      marginLeft="auto"
      marginRight="auto"
    >
      <Image
        src={getStaticPathIconFolder('loader/loader.svg')}
        alt="loader"
        width="25"
        height="25"
      />
    </Box>
  );
};
