import { FC } from 'react';

import { EPosition } from '@core/type';
import type { SanityVideo } from '@core/type/sanity';
import { addParameters, getLanguageFromTags } from '@core/util';

import { Box } from '../layout';
import { IFrame } from './IFrame';
import { IFrameYoutubeProps } from './interface-iframe';

export const IFrameYoutube: FC<IFrameYoutubeProps> = ({
  boxProps,
  language,
  src: videoLink,
  ...restProps
}) => {
  const languageAlpha2Code = getLanguageFromTags(language);
  const src = addParameters(videoLink, {
    rel: '0',
    hl: languageAlpha2Code,
    cc_lang_pref: languageAlpha2Code,
  });

  return (
    <IFrame
      allowFullScreen
      src={src}
      boxProps={{ borderStyle: 'none', ...boxProps }}
      {...restProps}
    />
  );
};

const getIFrameMargin = (align: EPosition) => {
  switch (align) {
    case EPosition.LEFT:
      return '0 auto 0 0';
    case EPosition.CENTER:
      return '0 auto';
    case EPosition.RIGHT:
      return '0 0 0 auto';
    default:
      return '0 auto';
  }
};

export const getIFrameYoutubeComponent = (section: SanityVideo) => {
  const iFrameWidth = section?.width || '100%';

  return (
    <Box
      position={'relative'}
      paddingBottom={`calc(56.25% * ${parseFloat(iFrameWidth)} / 100)`} // video aspect ratio: 16x9
      height={'0'}
      width={iFrameWidth}
      margin={getIFrameMargin(section.align)}
    >
      <IFrameYoutube
        src={section.url}
        title={section?.title}
        boxProps={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
        }}
      />
    </Box>
  );
};
