import { forwardRef } from 'react';
import styled, { CSSObject } from 'styled-components';

import { EFilenameBlur, getStaticPathImage, getStaticPathImageBlurData } from '@core/media';
import { EColor, WithThemeProps } from '@core/type';
import { getPaletteHandlers } from '@core/util';

import { ImageApi } from '../image';
import { Box } from '../layout';
import { CardMediaProps } from './interface-card';

export const CardMedia = forwardRef<HTMLDivElement, CardMediaProps>(
  ({ imageProps, withVideo, sizes, ...restProps }, ref) => (
    <Box position={'relative'} borderRadius={4} overflow={'hidden'} ref={ref} {...restProps}>
      <ImageApi
        {...imageProps}
        defaultImage={getStaticPathImage('card/article-default.png')}
        defaultImageBlurData={getStaticPathImageBlurData(EFilenameBlur['card/article-default.png'])}
        style={{
          objectFit: 'cover',
          objectPosition: 'center',
        }}
      />
      {withVideo && <StyledVideoIcon />}
    </Box>
  ),
);

CardMedia.displayName = 'CardMedia';

const StyledVideoIcon = styled(Box)(({ theme: { palette } }: WithThemeProps) => {
  const { getColor, getBoxShadow, getBackgroundColor } = getPaletteHandlers(palette);
  const borderTransparent = getColor(EColor.TRANSPARENT);
  const backgroundNeutralLight = getBackgroundColor({
    semanticType: EColor.NEUTRAL,
    variant: EColor.LIGHT,
  });

  return {
    position: 'absolute',
    left: 28,
    bottom: 20,
    width: 42,
    height: 42,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: getBoxShadow(EColor.ELEVATION_4),
    borderRadius: '50%',
    backgroundColor: backgroundNeutralLight,

    '&, &:after': {
      transition: 'background-color .3s, border-color .3s',
    },

    '&:after': {
      content: '""',
      flexShrink: 0,
      width: 0,
      height: 0,
      marginLeft: 4,
      borderTopColor: borderTransparent,
      borderLeftColor: getColor(EColor.PRIMARY),
      borderRightColor: borderTransparent,
      borderBottomColor: borderTransparent,
      borderStyle: 'solid',
      borderWidth: '8px 0 8px 12px',
    },

    '&:hover': {
      backgroundColor: getBackgroundColor({
        semanticType: EColor.PRIMARY,
        variant: EColor.DARK,
      }),

      '&:after': {
        borderLeftColor: backgroundNeutralLight,
      },
    },
  } as CSSObject;
});
